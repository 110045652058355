import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import { ICurrency, NetworkTypes } from 'types/networks';
import { warnInGroup } from 'utils/log';

export class CurrenciesApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getCurrenciesGroupedBySymbol(): Promise<ICurrency[]> {
    try {
      const { data } = await this._api.get(
        '/api/admin/currencies_grouped_by_symbols',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data.data;
    } catch (error) {
      warnInGroup('CurrenciesApi.getCurrenciesGroupedBySymbol', error);
    }
    return [];
  }

  async updateSwapFeeBySymbol(symbol: string, swapFee: string | number) {
    try {
      return this._api.put(
        `/api/admin/currency_swap_fee/${symbol}/${swapFee}`,
        {},
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );
    } catch (error) {
      warnInGroup('CurrenciesApi.updateSwapFeeBySymbol', error);
    }
    return [];
  }
}

export const currenciesApi = new CurrenciesApi();
