import { Close } from "assets/icons/Close";
import { Discord } from "assets/icons/Discord";
import ExpandArrow from "assets/icons/ExpandArrow";
import ImageIcon from "assets/icons/ImageIcon";
import InfoIcon from "assets/icons/infoIcon.svg";
import twitter from "assets/icons/twitter.png";
import ImagePlaceholder from "assets/images/dapps/imagePlaceholder.png";
import { Button } from "components/Button";
import { BASE_URL, TESTNET_API_URL } from "configs/api";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { dAppsAPI } from "screens/DApps/service";
import { useDAppsStore } from "screens/DApps/store";
import { IDApp, INetwork, ProjectAddFormValues } from "screens/DApps/types";
import { Tooltip } from "components/Tooltip";
import SearchIcon from "assets/icons/searchIcon.svg";
import CustomCheckbox from "components/Checkbox";
import { Slider } from "components/Slider";
import CheckIcon from "assets/icons/checkIcon.svg";
import { ProjectFormInput } from "./ProjectFormInput";
import { ProjectFormSwitch } from "./ProjectFormSwitch";

export const ProjectEditForm = ({
  dApp,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  dApp?: IDApp;
}) => {
  const [networks, setNetworks] = useState<INetwork[]>([]);
  const [imagePreview, setImagePreview] = useState<string>(
    dApp?.image || ImagePlaceholder
  );
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { register, handleSubmit, watch, reset, control, setValue } =
    useForm<ProjectAddFormValues>({
      defaultValues: {
        id: dApp?.id || "",
        projectName: dApp?.name || "",
        projectDescription: dApp?.description || "",
        tag: dApp?.tag || "",
        websiteLink: dApp?.website || "https://",
        twitterLink: dApp?.twitter_url || "",
        discordLink: dApp?.discord_url || "",
        networks: dApp?.networks || [],
        isPotentialAirdrop: dApp?.potential_airdrop || false,
        points: dApp?.points || 1,
      },
    });

  const { isAddLoading } = useDAppsStore();

  const file = watch("file");
  const watchedNetworks = watch("networks");
  const points = watch("points");

  useEffect(() => {
    (async () => {
      const networksFromServer = await dAppsAPI.getNetworks();
      if (networksFromServer) {
        setNetworks(networksFromServer);
      }
    })();
  }, []);

  useEffect(() => {
    if (file && file.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setImagePreview(fileReader.result as string);
      };
      fileReader.readAsDataURL(file[0]);
    }
  }, [file]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".dropdown-container")) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSubmit: SubmitHandler<ProjectAddFormValues> = async (data) => {
    await dAppsAPI.editDApp(data, async () => {
      onClose();
    });
  };

  const isSubmitDisabled =
    !watch("file") ||
    !watch("projectName") ||
    !watch("projectDescription") ||
    !watch("networks").length ||
    watch("networks").some((network) => !network.id || !network.name) ||
    !watch("tag") ||
    !watch("websiteLink");

  const getNetworkImageUrl = (name: string) => {
    const network = networks.find((network) => network.name === name);
    return network ? network.image_url : "";
  };

  const handleNetworkToggle = (networkName: string) => {
    const existingNetwork = watchedNetworks.find(
      (network) => network.name === networkName
    );
    if (existingNetwork) {
      setValue(
        "networks",
        watchedNetworks.filter((network) => network.name !== networkName)
      );
    } else {
      const network = networks.find((network) => network.name === networkName);
      if (network) {
        setValue("networks", [
          ...watchedNetworks,
          {
            id: network.id,
            name: network.name,
            image_url: network.image_url,
            is_testnet: network.is_testnet,
          },
        ]);
      }
    }
  };

  const filteredNetworks = networks
    .filter((network) =>
      network.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(
      (network, index, self) =>
        index === self.findIndex((n) => n.name === network.name)
    );

  return (
    <>
      {isOpen && (
        <div
          className="fixed z-9 bg-black/40 top-0 left-0 right-0 bottom-0 w-full"
          onClick={onClose}
        />
      )}

      <div
        className={`fixed w-full md:w-[487px] h-[100svh] overflow-y-auto custom-scrollbar px-[20px] md:px-[40px] pt-[60px] top-0 bottom-0 right-0 bg-black md:border-l-[1px] z-10 transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="flex text-[24px] font-[700] leading-[32px] justify-between items-center mb-[40px]">
          <h3>Edit project</h3>
          <button onClick={onClose}>
            <Close />
          </button>
        </div>

        <form
          id="project-edit-form"
          onSubmit={handleSubmit(onSubmit)}
          className={`flex flex-col gap-[24px] h-fit`}
        >
          <div className="flex gap-[24px] w-full">
            <div className="min-w-[80px] md:min-w-[108px] max-w-[80px] md:max-w-[108px] h-[80px] md:h-[108px] rounded-full overflow-hidden custom-scrollbar flex justify-center items-center bg-[#FFFFFF1A]">
              <img
                src={imagePreview}
                alt=""
                className="w-full h-full object-cover object-center"
              />
            </div>

            <div className="flex flex-col gap-[8px] justify-center">
              <input
                id="logo"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                {...register("file")}
              />
              <label
                htmlFor="logo"
                className="w-[190px] h-[43px] bg-[#FFFFFF1A] flex items-center justify-center rounded-full"
              >
                <button
                  type="button"
                  className="flex items-center justify-center gap-[10px] text-[14px] font-[500] leading-[18.9px]"
                  onClick={() => document.getElementById("logo")?.click()}
                >
                  <ImageIcon />
                  Upload project logo
                </button>
              </label>
              <p className="opacity-70 text-[12px] font-[500] text-center">
                max 5mb, .jpeg, .png
              </p>
            </div>
          </div>

          <ProjectFormInput
            title="Project name"
            placeholder="Add name here"
            control={control}
            name="projectName"
          />

          <div className="flex flex-col gap-[4px]">
            <p className="ml-1 text-[12px] leading-[17px] font-[500] max-h-[48px] text-[#B5B2C9]">
              Choose network
            </p>

            <div className="relative dropdown-container">
              <button
                type="button"
                className="bg-[#FFFFFF1A] mr-[6px] py-[12px] md:mr-0 min-h-[48px] px-[20px] rounded-[8px] w-full text-[14px] leading-[17px] font-[500] placeholder:opacity-50 placeholder:text-[#fff] flex items-center justify-between"
                onClick={() => setOpenDropdown(!openDropdown)}
              >
                <div className="flex flex-col gap-[10px] items-start">
                  {watchedNetworks.length > 0 ? (
                    watchedNetworks.map((network) => (
                      <div
                        key={network.name}
                        className="flex items-center gap-[8px]"
                      >
                        <img
                          src={`${
                            network.is_testnet ? TESTNET_API_URL : BASE_URL
                          }${getNetworkImageUrl(network.name)}`}
                          alt=""
                          className="w-[24px] h-[24px] inline-block"
                        />
                        <span>{network.name}</span>
                      </div>
                    ))
                  ) : (
                    <span>Select network</span>
                  )}
                </div>
                <ExpandArrow className={openDropdown ? "rotate-180" : ""} />
              </button>

              {openDropdown && (
                <div
                  id="dropdown"
                  className="absolute top-[108%] left-0 p-[20px] rounded-[8px] w-full h-[300px] overflow-y-auto custom-scrollbar z-20 bg-gradient-to-br from-[#1D1D1D] to-[#111111] border-[1px] border-[#FFFFFF33]"
                >
                  <div className="bg-[#FFFFFF0D] h-[48px] rounded-[8px] mb-[10px] w-full px-[20px] flex items-center justify-between gap-[8px]">
                    <img src={SearchIcon} alt="" />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full text-[14px] leading-[17px] font-[500] text-[#fff] bg-transparent"
                      style={{ outline: "none" }}
                    />
                  </div>
                  {filteredNetworks.map((network) => (
                    <div
                      key={network.name}
                      className="flex items-center gap-[16px] cursor-pointer py-[5px]"
                      onClick={() => handleNetworkToggle(network.name)}
                    >
                      <CustomCheckbox
                        checked={watchedNetworks.some(
                          (n) => n.name === network.name
                        )}
                        onChange={() => handleNetworkToggle(network.name)}
                      />
                      <img
                        src={`${
                          network.is_testnet ? TESTNET_API_URL : BASE_URL
                        }${network.image_url}`}
                        alt=""
                        className="w-[24px] h-[24px] inline-block"
                      />
                      {network.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-[4px]">
            <p className="ml-1 text-[12px] leading-[17px] font-[500] text-[#B5B2C9]">
              Project description
            </p>

            <div className="relative">
              <textarea
                placeholder="Add description here"
                className="bg-[#FFFFFF1A] py-[15.5px] px-[20px] rounded-[8px] flex items-center min-h-[103px] max-h-[103px] w-full text-[14px] leading-[17px] font-[500] placeholder:opacity-50 placeholder:text-[#fff] custom-scrollbar"
                style={{ outline: "none", resize: "none" }}
                {...register("projectDescription")}
              />
              <p className="absolute bottom-[12px] right-[20px] py-[4px] px-[6px] bg-[#3f3f3f] rounded-[8px] text-[#FFFFFF80]">
                {watch("projectDescription")?.length || 0}
              </p>
            </div>
          </div>

          <ProjectFormInput
            title="Add tag"
            placeholder="Add tag"
            control={control}
            name="tag"
          />

          <div className="flex flex-col gap-[16px]">
            <p className="ml-1 text-[12px] leading-[17px] font-[500] text-[#B5B2C9]">
              Points
            </p>

            <Controller
              name="points"
              control={control}
              render={({ field }) => (
                <Slider max={5} value={field.value} setValue={field.onChange} />
              )}
            />
          </div>

          <ProjectFormInput
            title="Website link"
            control={control}
            name="websiteLink"
          />
          <ProjectFormInput
            title="Twitter link"
            placeholder="Twitter link"
            startElement={
              <img
                src={twitter}
                alt=""
                className="h-[16px] w-[16px] mr-[10px]"
              />
            }
            control={control}
            name="twitterLink"
          />
          <ProjectFormInput
            title="Discord link"
            placeholder="Discord link"
            startElement={
              <Discord width={22} height={16} className="mr-[10px]" />
            }
            control={control}
            name="discordLink"
          />

          <div className="flex gap-[8px] items-center">
            <Controller
              name="isPotentialAirdrop"
              control={control}
              render={({ field }) => (
                <ProjectFormSwitch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              )}
            />
            <span>Potential Airdrop</span>

            <Tooltip
              variant="dark"
              trigger={
                <img src={InfoIcon} alt="" className="min-w-fit min-h-fit" />
              }
              contentProps={{
                align: "end",
                avoidCollisions: true,
              }}
            >
              Protocols with upcoming token launch
            </Tooltip>
          </div>
        </form>

        <div className="bg-gradient-to-t from-black to-transparent pb-[40px] pt-[60px] flex items-center w-full left-0 right-0 z-10">
          <Button
            extraClass="w-full flex justify-center items-center gap-[10px]"
            type="submit"
            form="project-edit-form"
            disabled={isAddLoading || isSubmitDisabled}
          >
            <span className="text-[18px]">
              <img
                src={CheckIcon}
                alt=""
                className="w-[16px] h-[16px] mt-[2px]"
              />
            </span>{" "}
            Save project
          </Button>
        </div>
      </div>
    </>
  );
};
