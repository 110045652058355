import React from "react";
import { useController, UseControllerProps, Control } from "react-hook-form";

interface ProjectAddFormInputProps extends UseControllerProps {
  title?: string;
  placeholder?: string;
  startElement?: React.ReactElement;
  className?: string;
  control: Control<any>;
}

export const ProjectFormInput = ({
  title,
  placeholder,
  startElement,
  className,
  control,
  name,
  rules,
  defaultValue,
}: ProjectAddFormInputProps) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className="flex flex-col gap-[4px]">
      <p className="ml-1 text-[12px] leading-[17px] font-[500] text-[#B5B2C9]">
        {title}
      </p>
      <div
        className={`bg-[#FFFFFF1A] py-[15.5px] px-[20px] rounded-[8px] flex items-center max-h-[48px] ${
          !!className ? className : ""
        }`}
      >
        {startElement}
        <input
          type="text"
          placeholder={placeholder}
          className="w-full bg-transparent text-[14px] leading-[17px] font-[500] placeholder:opacity-50 placeholder:text-[#fff]"
          style={{ outline: "none" }}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          ref={ref}
        />
      </div>
    </div>
  );
};
