import { Logo } from "assets/icons/Logo";
import bg from "../../../../assets/images/dapps/signInBg.png";
import { Button } from "components/Button";
import { useState } from "react";
import { authAPI } from "services/auth";
import { useNotify } from "hooks/useToast";
import { AxiosError } from "axios";

export const DAppsSignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {notify} = useNotify();

  const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await authAPI.authDAppsAdmin({ email, password });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
      }}
      className="h-[100svh] w-full bg-no-repeat flex flex-col items-center justify-center md:p-[40px] p-[20px] md:bg-contain bg-cover bg-top md:bg-left-top pb-[5svh] md:pb-[20svh]"
    >
      <Logo className="fixed md:left-[52px] mx-auto top-[54px]" />
      <h1 className="text-[24px] md:text-[36px] font-[700] mb-[60px]">
        Dapps Explorer Admin
      </h1>

      <form onSubmit={handleSignIn} className="w-full max-w-[427px]">
        <div className="mb-[24px]">
          <div className="text-[14px] font-[500] mb-[8px]">Your Email</div>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-[#FFFFFF1A] outline-none px-[20px] py-[16px] font-[400] text-[14px] h-[50px] w-full rounded-[8px]"
          />
        </div>

        <div className="mb-[36px]">
          <div className="text-[14px] font-[500] mb-[8px]">Your Password</div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="bg-[#FFFFFF1A] outline-none px-[20px] py-[16px] font-[400] text-[18px] h-[50px] w-full rounded-[8px]"
          />
        </div>

        <Button extraClass="w-full" type="submit" disabled={isLoading}>
          Sign In
        </Button>
      </form>
    </div>
  );
};
