import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import { ISimpleApiResp } from 'types';
import { NetworkTypes } from 'types/networks';
import { WeeklyQuestDates, WeeklyQuestEndpoint } from 'types/weeklyQuests';
import { warnInGroup } from 'utils/log';

export class WeeklyQuestApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getDates() {
    try {
      const { data } = await this._api.get<ISimpleApiResp<WeeklyQuestDates[]>>(
        '/api/admin/daily_quest_dates',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data.data;
    } catch (error) {
      warnInGroup('NetworksApi.getNetworks', error);
    }
    return [];
  }

  async setDates(dates: WeeklyQuestDates) {
    await this._api.put(
      `/api/admin/set_date/${dates.start_date}/${dates.end_date}/${dates.setting_name}`,
      {},
      {
        headers: {
          'network-type': NetworkTypes.EVM,
        },
      }
    );
  }

  async getEndpoints(): Promise<WeeklyQuestEndpoint[]> {
    try {
      const { data } = await this._api.get<WeeklyQuestEndpoint[]>(
        '/api/admin/all_daily_quests',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data;
    } catch (error) {
      warnInGroup('NetworksApi.getNetworks', error);
    }
    return [];
  }
}

export const weeklyQuestApi = new WeeklyQuestApi();
