import { SVGAttributes } from "react";

export function CalendarIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M14.75 3.00024H4.25C3.42157 3.00024 2.75 3.67182 2.75 4.50024V15.0002C2.75 15.8287 3.42157 16.5002 4.25 16.5002H14.75C15.5784 16.5002 16.25 15.8287 16.25 15.0002V4.50024C16.25 3.67182 15.5784 3.00024 14.75 3.00024Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 1.50024V4.50024"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 1.50024V4.50024"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.75 7.50024H16.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}