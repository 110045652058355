import axios, { AxiosInstance } from "axios";
import { BASE_URL, stage } from "configs/api";
import { IDApp, INetwork, ProjectAddFormValues } from "./types";
import { dAppsStore } from "./store";

class DAppsApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  private handleError(error: any) {
    if (error.response && error.response.data.message === "Access token expired") {
      localStorage.removeItem('accessToken');
    }

    if (stage === 'development') {
      console.warn(error, '======error');
    }
  }

  async getDApps(skip: number = 0, take: number = 10, refetch?: boolean): Promise<IDApp[]> {
    const url = `/api/dapps_info/all`;
    try {
      const { data } = await this._api.get<IDApp[]>(url, {
        params: { skip, take }
      });

      if (refetch) {
        dAppsStore.setState({ dApps: data, hasMore: true, page: 1 })
      }

      return data;
    } catch (error) {
      this.handleError(error);
      return [];
    }
  }

  async getNetworks() {
    const url = `/api/dapps_info/networks`;
    try {
      const { data } = await this._api.get<INetwork[]>(url, {
        params: {
          category: "All",
        }
      });

      return data;
    } catch (error) {
      stage === 'development' &&
        this.handleError(error);
    }
  }

  async createDApp(projectData: ProjectAddFormValues, callback?: () => void) {
    const url = `/api/dapps_info/add`;
    const accessToken = localStorage.getItem('accessToken');
    dAppsStore.setState({ isAddLoading: true })

    try {
      const formData = new FormData();
      formData.append('file', projectData.file[0]);
      formData.append('name', projectData.projectName);
      formData.append('networks', JSON.stringify(projectData.networks));
      formData.append('description', projectData.projectDescription);
      formData.append('tag', projectData.tag);
      formData.append('website', projectData.websiteLink);
      formData.append('twitter_url', projectData.twitterLink);
      formData.append('discord_url', projectData.discordLink);
      formData.append('type', "All");
      formData.append('potential_airdrop', projectData.isPotentialAirdrop.toString());
      formData.append('points', projectData.points.toString());

      await this._api.post<INetwork[]>(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.getDApps(0, 10, true);

      if (!!callback) {
        callback()
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      dAppsStore.setState({ isAddLoading: false })
    }
  }

  async editDApp(projectData: ProjectAddFormValues, callback?: () => void) {
    const url = `/api/dapps_info/edit`;
    const accessToken = localStorage.getItem('accessToken');
    dAppsStore.setState({ isAddLoading: true })

    try {
      const formData = new FormData();
      if (projectData.file[0]) {
        formData.append('file', projectData.file[0]);
      }
      formData.append('id', projectData.id);
      formData.append('name', projectData.projectName);
      formData.append('networks', JSON.stringify(projectData.networks));
      formData.append('description', projectData.projectDescription);
      formData.append('tag', projectData.tag);
      formData.append('website', projectData.websiteLink);
      formData.append('twitter_url', projectData.twitterLink);
      formData.append('discord_url', projectData.discordLink);
      formData.append('type', "All");
      formData.append('potential_airdrop', projectData.isPotentialAirdrop.toString());
      formData.append('points', projectData.points.toString());

      await this._api.patch<INetwork[]>(url, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.getDApps(0, 10, true);

      if (!!callback) {
        callback()
      }
    } catch (error) {
      this.handleError(error);
    } finally {
      dAppsStore.setState({ isAddLoading: false })
    }
  }

  async deleteDApp({ dAppId }: { dAppId: string }) {
    const url = `/api/dapps_info/${dAppId}`;
    const accessToken = localStorage.getItem('accessToken');

    try {
      await this._api.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.getDApps();
    } catch (error) {
      this.handleError(error);
    }
  }
}

export const dAppsAPI = new DAppsApi();
