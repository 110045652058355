import { useEffect } from "react";
import TrashIcon from "assets/icons/TrashIcon";
import { Button } from "components/Button";

export const DeleteModal = ({
  isOpen,
  onClose,
  onDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleBackdropClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black/60 z-50"
      onClick={handleBackdropClick}
    >
      <div className="w-[382px] mx-[20px] min-h-[242px] rounded-[32px] border-[1px] border-[#5F5B76] pt-[40px] pb-[32px] px-[50px] flex flex-col items-center bg-black">
        <p className="text-[22px] font-[500] leading-[28px] text-center w-full mb-[32px]">
          Are you sure you want <br /> to delete this project?
        </p>

        <Button extraClass="mb-[16px]" onClick={onClose}>
          No, cancel
        </Button>

        <button
          className="flex gap-[8px] font-[700] text-[14px] leading-[21px] text-[#FF93A6]"
          onClick={onDelete}
        >
          <TrashIcon width={16} height={16} />
          Yes, delete
        </button>
      </div>
    </div>
  );
};
