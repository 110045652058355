import axios, { AxiosInstance } from 'axios';
import { BULK_API_URL } from 'configs/api';
import { ISimpleApiResp } from 'types';
import { AdvancedModeDelays } from 'types/advancedMode';
import { NetworkTypes } from 'types/networks';
import { warnInGroup } from 'utils/log';

export class AdvancedModeApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BULK_API_URL,
      withCredentials: true,
    });
  }

  async getDelays(): Promise<AdvancedModeDelays | null> {
    try {
      const { data } = await this._api.get<ISimpleApiResp<AdvancedModeDelays>>(
        '/api/bulk_order/execution/type',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data.data;
    } catch (error) {
      warnInGroup('NetworksApi.getNetworks', error);
    }
    return null;
  }

  async setDelays(delays: AdvancedModeDelays) {
    await this._api.patch(
      `/api/admin/set_delay_mode`,
      {
        ...delays,
      },
      {
        headers: {
          'network-type': NetworkTypes.EVM,
        },
      }
    );
  }
}

export const advancedModeApi = new AdvancedModeApi();
