import { Logo } from 'assets/icons/Logo';
import { WalletMenu } from 'components/WalletMenu';
import { ROUTES } from 'constants.routes';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserStore } from 'stores/user';
import { UserRoles } from 'types/user';
import { HeaderMobileMenu } from './HeaderMobileMenu';
import './styles.css';

export function Header() {
  const { pathname } = useLocation();
  const user = useUserStore((s) => s.user);

  const isSignIn = pathname === '/apanel/sign-in';

  return (
    <header className="flex items-center pl-2 pr-5 py-[1.6875rem] md:px-10 md:py-[2.1875rem]">
      <div className="px-3 py-3.5 mr-auto">
        <Logo />
      </div>
      {!isSignIn && user?.wallet_role === UserRoles.ADMIN && (
        <>
          <nav className="hidden lg:flex items-center gap-[3.125rem] ml-auto mr-6">
            <NavLink
              to={ROUTES.API_ENDPOINTS}
              end
              className="text-white text-sm font-medium header-nav-item"
            >
              API EndPoints
            </NavLink>
            <NavLink
              to={ROUTES.MAINNET}
              end
              className="text-white text-sm font-medium header-nav-item"
            >
              Mainnet
            </NavLink>
            <NavLink
              to={ROUTES.TESTNET}
              end
              className="text-white text-sm font-medium header-nav-item"
            >
              Testnet
            </NavLink>
            <NavLink
              to={ROUTES.HEDGE_BOT}
              end
              className="text-white text-sm font-medium header-nav-item"
            >
              Hedge bot
            </NavLink>
            <NavLink
              to={ROUTES.REBALANCE_BOT}
              end
              className="text-white text-sm font-medium header-nav-item"
            >
              Rebalance bot
            </NavLink>
          </nav>
          <WalletMenu />
          <HeaderMobileMenu />
        </>
      )}
    </header>
  );
}
