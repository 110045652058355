import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { AuthController } from 'components/Auth';
import { PrivateRoute } from 'components/PrivateRoute';
import { stage } from 'configs/api';
import { wagmiConfig } from 'configs/wagmi';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { WagmiConfig } from 'wagmi';

import { ApiEndPointsView } from 'screens/ApiEndPoints';
import { DApps } from 'screens/DApps';
import { HedgeBotView } from 'screens/HedgeBot';
import { MainView } from 'screens/Main';
import { RebalanceBotView } from 'screens/RabalanceBot/RebalanceBotView';
import { SingIn } from 'screens/SignIn';

import '@rainbow-me/rainbowkit/styles.css';
import { UserRoles } from 'types/user';

const routes = [
  {
    path: '/',
    element: <AuthController />,
    children: [
      {
        path: '/apanel',
        element: (
          <PrivateRoute>
            <MainView />
          </PrivateRoute>
        ),
      },
      {
        path: '/apanel/hedge',
        element: (
          <PrivateRoute>
            <HedgeBotView />
          </PrivateRoute>
        ),
      },
      {
        path: '/apanel/rebalance',
        element: (
          <PrivateRoute>
            <RebalanceBotView />
          </PrivateRoute>
        ),
      },
      {
        path: '/apanel/api-endpoints',
        element: (
          <PrivateRoute>
            <ApiEndPointsView />
          </PrivateRoute>
        ),
      },
      {
        path: '/apanel/sign-in',
        element: <SingIn />,
      },
      {
        path: '/endpoints',
        element: (
          <PrivateRoute role={UserRoles.MODERATOR}>
            <ApiEndPointsView />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/dapps-admin',
    element: <DApps />,
  },
];

const routers = {
  development: createHashRouter,
  staging: createBrowserRouter,
  production: createBrowserRouter,
};

const router = routers[stage](routes, {
  basename: '/',
});

function App() {
  useEffect(() => {
    if (window.location.pathname === '/') {
      if (stage === 'production') {
        window.open('https://retrobridge.io/', '_self');
      } else if (stage === 'staging') {
        window.open('/apanel', '_self');
      } else if (
        stage === 'development' &&
        (window.location.hash === '' || window.location.hash === '#/')
      ) {
        window.open('/#/apanel', '_self');
      }
    }
  }, []);

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={[]} theme={darkTheme()} locale="en">
        <ToastContainer className="toast-position" />
        <RouterProvider router={router} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
