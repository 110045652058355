import CheckIcon from "assets/icons/checkIcon.svg";

const CustomCheckbox = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: () => void;
}) => {
  return (
    <div
      className={`h-[20px] w-[20px] rounded cursor-pointer flex items-center justify-center ${
        checked
          ? "bg-gradient-to-br from-[#D196FF] to-[#9D8DFF]"
          : "bg-[#FFFFFF1A]"
      }`}
      onClick={onChange}
    >
      {checked && <img src={CheckIcon} alt="" />}
    </div>
  );
};

export default CustomCheckbox;
