import { CalendarIcon } from 'assets/icons/Calendar';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { WeeklyQuestDateForm } from './scheme';

interface WeeklyItemProps {
  index: number;
}

export function WeeklyItem({ index }: WeeklyItemProps) {
  const form = useFormContext<WeeklyQuestDateForm>();
  const name = useWatch({ name: `dates.${index}.preference_name` });

  return (
    <div className="flex flex-col gap-6">
      <h3>{name}</h3>
      <div className="flex w-full gap-[0.8125rem]">
        <div className="flex flex-col w-full gap-2">
          <span className="font-medium text-sm leading-[1.0625rem] text-[#B5B2C9] cursor-text">
            Start date
          </span>
          <label className="flex items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text">
            <Controller
              name={`dates.${index}.start_date`}
              control={form.control}
              render={({ field }) => (
                <ReactDatePicker
                  className="bg-transparent font-medium text-sm leading-[1.0625rem] text-white outline-none w-full"
                  dateFormat={'dd MMM yyyy'}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  onBlur={field.onBlur}
                />
              )}
            />
            <CalendarIcon className="ml-auto cursor-pointer" />
          </label>
        </div>
        <div className="flex flex-col w-full gap-2">
          <span className="font-medium text-sm leading-[1.0625rem] text-[#B5B2C9] cursor-text">
            End date
          </span>
          <label className="flex items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text">
            <Controller
              name={`dates.${index}.end_date`}
              control={form.control}
              render={({ field }) => (
                <ReactDatePicker
                  className="bg-transparent  font-medium text-sm leading-[1.0625rem] text-white outline-none w-full"
                  dateFormat={'dd MMM yyyy'}
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  onBlur={field.onBlur}
                />
              )}
            />
            <CalendarIcon className="ml-auto cursor-pointer" />
          </label>
        </div>
      </div>
    </div>
  );
}
