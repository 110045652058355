import { create, useStore } from "zustand";
import { IDApp } from "./types";

export interface IDAppsStore {
  isAuthorized: boolean;
  dApps: IDApp[];
  isAddLoading: boolean;
  page: number;
  setDApps: (dApps: IDApp[]) => void;
  addDApps: (dApps: IDApp[]) => void;
  setPage: (page: number) => void;
  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;
  dAppForEdit: IDApp | null;
  setDAppForEdit: (dAppForEdit: IDApp | null) => void,
}

export const dAppsStore = create<IDAppsStore>((set) => ({
  isAuthorized: false,
  dApps: [],
  isAddLoading: false,
  page: 0,
  setDApps: (dApps) => set({ dApps }),
  addDApps: (dApps) => set((state) => ({ dApps: [...state.dApps, ...dApps] })),
  setPage: (page) => set({ page }),
  hasMore: true,
  setHasMore: (hasMore) => set({ hasMore }),
  dAppForEdit: null,
  setDAppForEdit: (dAppForEdit) => set({ dAppForEdit }),
}));

export const useDAppsStore = () => useStore(dAppsStore);
