import { DropdownIcon } from 'assets/icons/DropdownIcon';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { Button } from 'components/Button';
import { BASE_URL } from 'configs/api';
import { useNotify } from 'hooks/useToast';
import { useWindowResize } from 'hooks/useWindowResize';
import { chunk } from 'lodash';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { networksApi } from 'services/networks';
import useSWR from 'swr';
import { INetwork } from 'types/networks';

interface NetworkMultiplierForm {
  networks: INetwork[];
}

export function NetworkMultiplier() {
  const [isExpanded, setIsExpanded] = useState(false);
  const { width } = useWindowResize();
  const { data, mutate } = useSWR('networks', () => networksApi.getNetworks(), {
    revalidateOnFocus: false,
  });
  const { notify } = useNotify();

  const form = useForm<NetworkMultiplierForm>({
    values: {
      networks: data || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const formValues = form.getValues();

      const coefficients = formValues.networks.map((network, i) => [
        network.name,
        +network.gas_multiplier || 1,
      ]);

      await networksApi.setGasMultiplierCoefficients(
        Object.fromEntries(coefficients)
      );

      await mutate();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  const [virtualGrid, cols] = useMemo(() => {
    let cols = 4;

    if (width < 768) {
      cols = 1;
    } else if (width < 1024) {
      cols = 2;
    } else if (width < 1280) {
      cols = 3;
    }

    return [
      data ? chunk(data, Math.ceil(data.length / cols)) : [],
      data ? Math.ceil(data.length / cols) : 0,
    ];
  }, [data, width]);

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="mb-[3.75rem] border-[#B5B2C9] border-opacity-30 md:mb-[3.3125rem] md:pb-[3.3125rem] md:border-b"
    >
      <div className="flex justify-between items-center pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Gas Fee Multipliers
        </h2>
        <Button
          type="submit"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          extraClass="hidden md:block"
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <FormProvider {...form}>
        <div className="grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-16 gap-y-2">
          {data &&
            virtualGrid.map((chunk, k) => (
              <div key={`chunk-${k}`} className="flex flex-col gap-x-8 gap-y-2">
                {chunk
                  .slice(0, width < 768 && !isExpanded ? 5 : data.length)
                  .map((network, i) => (
                    <div key={i + k * cols} className="flex items-center">
                      <img
                        src={BASE_URL + network.network_image_url}
                        className="w-7 min-w-7 h-7 mr-3 "
                        alt=""
                      />
                      <span className="text-sm">{network.name}</span>
                      <input
                        type="number"
                        min={0.000001}
                        step={0.000001}
                        required
                        className="grow w-full ml-auto flex max-w-[94px] items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text input-number"
                        {...form.register(
                          `networks.${i + k * cols}.gas_multiplier`
                        )}
                      />
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </FormProvider>
      <button
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center gap-2 mx-auto mt-8 text-sm leading-5 font-bold md:hidden"
      >
        {isExpanded ? 'Show less' : 'Show all'}
        <DropdownIcon className={clsx(isExpanded && 'rotate-180')} />
      </button>
      <Button
        disabled={!form.formState.isDirty || form.formState.isSubmitting}
        type="submit"
        extraClass="block mx-auto mt-6 md:hidden"
      >
        {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  );
}
