import { SVGAttributes } from "react";

export function Trashcan(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M3 6.00024H5H21"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.00024V4.00024C8 3.46981 8.21071 2.9611 8.58579 2.58603C8.96086 2.21096 9.46957 2.00024 10 2.00024H14C14.5304 2.00024 15.0391 2.21096 15.4142 2.58603C15.7893 2.9611 16 3.46981 16 4.00024V6.00024M19 6.00024V20.0002C19 20.5307 18.7893 21.0394 18.4142 21.4145C18.0391 21.7895 17.5304 22.0002 17 22.0002H7C6.46957 22.0002 5.96086 21.7895 5.58579 21.4145C5.21071 21.0394 5 20.5307 5 20.0002V6.00024H19Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.0002V17.0002"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.0002V17.0002"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
