import * as ReactTooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { ReactNode, useRef } from "react";
import { TooltipArrow } from "./TooltipArrow";

interface TooltipProps extends ReactTooltip.TooltipProps {
  children?: ReactNode;
  trigger?: ReactNode;
  triggerProps?: ReactTooltip.TooltipTriggerProps;
  variant?: "default" | "dark" | "dark-2";
  contentProps?: ReactTooltip.TooltipContentProps;
}

export function Tooltip({
  children,
  trigger,
  triggerProps,
  variant,
  contentProps,
  ...props
}: TooltipProps) {
  const triggerRef = useRef<HTMLButtonElement>(null);

  return (
    <ReactTooltip.Provider>
      <ReactTooltip.Root {...props}>
        <ReactTooltip.Trigger
          ref={triggerRef}
          onClick={(event) => event.preventDefault()}
          {...triggerProps}
          className={clsx(
            "flex justify-center items-center min-w-[12px] w-[12px] h-[12px] p-0 mr-auto ml-[6px] bg-[rgba(255,255,255,0.15)] border-none rounded-full text-[8px] font-medium leading-[12px] tracking-[0em] text-center text-white",
            {
              "inline-flex": triggerProps?.className?.includes(
                "fee-tooltip-trigger"
              ),
            },
            triggerProps?.className
          )}
        >
          {trigger || "?"}
        </ReactTooltip.Trigger>
        <ReactTooltip.Portal>
          <ReactTooltip.Content
            side="top"
            align="start"
            alignOffset={-15}
            avoidCollisions={false}
            sideOffset={5}
            asChild
            onPointerDownOutside={(event) => {
              if (event.target === triggerRef.current) event.preventDefault();
            }}
            {...contentProps}
          >
            <div
              className={clsx(
                "relative flex justify-center items-center w-[172px] h-[64px] p-[12px_8px_20px_15px] rounded-[12px] z-[100000] text-[10px] font-medium leading-[14px] tracking-[0em] text-left text-[#B5B2C9]",
                {
                  "w-[212px] h-auto min-h-[54px] p-[12px_8px_12px_15px] bg-[#4d476c]":
                    variant === "default",
                  "bg-[#29234E]": variant === "dark",
                  "bg-[rgba(29,23,56,0.5)] w-auto": variant === "dark-2",
                },
                variant
              )}
            >
              <div className="absolute inset-0 border-[0.5px] border-white/25 rounded-[inherit] z-[-1] bg-[var(--background-color)]"></div>
              {variant === "dark-2" && (
                <div className="absolute inset-0 backdrop-blur-[120px]"></div>
              )}
              <TooltipArrow
                className={clsx({ "text-[#322d53]": variant === "dark-2" })}
              />
              {children}
            </div>
          </ReactTooltip.Content>
        </ReactTooltip.Portal>
      </ReactTooltip.Root>
    </ReactTooltip.Provider>
  );
}
