import axios, { AxiosInstance } from 'axios';
import { BASE_URL, BULK_API_URL, stage } from 'configs/api';
import { dAppsStore } from 'screens/DApps/store';
import { userStore } from 'stores/user';
import { AppTypedData, ISimpleApiResp } from 'types';
import { NetworkTypes } from 'types/networks';
import { User, UserRoles } from 'types/user';
import { Signature } from 'viem';

class AuthApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getSignMessage() {
    const url = `/api/wallet_auth/message`;
    try {
      const { data } = await this._api.get<ISimpleApiResp<AppTypedData>>(url);

      return data;
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error getSignMessage');
      throw error;
    }
  }

  async validateSignedMessage(
    wallet_address: string,
    signature: string | Signature
  ) {
    const url = `/api/wallet_auth/message`;
    const bulkURL = `${BULK_API_URL}/api/wallet_auth/message`;
    // const botURL = `${BOT_API_URL}/api/wallet_auth/message`;
    try {
      const role = await this.getWalletRole(wallet_address);

      if (role !== UserRoles.ADMIN && role !== UserRoles.MODERATOR) {
        throw new Error('Not an admin wallet');
      }

      await this._api.post(url, {
        wallet_address,
        network_type: NetworkTypes.EVM,
        signature,
      });
      await this._api.post(bulkURL, {
        wallet_address,
        network_type: NetworkTypes.EVM,
        signature,
      });
      // TODO: uncomment this when bot api is ready
      // await this._api.post(botURL, {
      //   wallet_address,
      //   network_type: NetworkTypes.EVM,
      //   signature,
      // });
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error validateSignedMessage');
      throw error;
    }
  }

  async checkWallet(wallet_address: string) {
    const url = `/api/wallet_auth/wallet/${wallet_address}`;
    const bulkURL = `${BULK_API_URL}/api/wallet_auth/wallet/${wallet_address}`;
    // const botURL = `${BOT_API_URL}/api/wallet_auth/wallet/${wallet_address}`;
    try {
      await this._api.get(url, {
        headers: { 'network-type': NetworkTypes.EVM },
      });
      await this._api.get(bulkURL, {
        headers: { 'network-type': NetworkTypes.EVM },
      });
      // TODO: uncomment this when bot api is ready
      // await this._api.get(botURL, {
      //   headers: { 'network-type': NetworkTypes.EVM },
      // });
      const role = await this.getWalletRole(wallet_address);

      if (role !== UserRoles.ADMIN && role !== UserRoles.MODERATOR) {
        throw new Error('Not an admin wallet');
      }
    } catch (error) {
      stage === 'development' && console.warn(error, '======error checkWallet');
      throw error;
    }
  }

  async getWalletRole(wallet_address: string) {
    const url = `/api/admin/wallet_role/${wallet_address}`;
    const { setUser } = userStore.getState();
    try {
      const { data } = await this._api.get<ISimpleApiResp<User>>(url, {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      setUser(data.data);

      return data.data.wallet_role;
    } catch (error) {
      stage === 'development' &&
        console.warn(error, '======error isWalletAdmin');
      throw error;
    }
  }

  async authDAppsAdmin({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    const url = `/api/dapps_admin/login`;
    try {
      const { data } = await this._api.post<{ accessToken: string }>(url, {
        username: email,
        password,
      });

      localStorage.setItem('accessToken', data.accessToken);
      dAppsStore.setState({ isAuthorized: true });
    } catch (error) {
      throw error;
    }
  }
}

export const authAPI = new AuthApi();
