import { Logo } from "assets/icons/Logo";
import { Button } from "components/Button";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { dAppsAPI } from "screens/DApps/service";
import { useDAppsStore } from "screens/DApps/store";
import { DAppCard } from "../DAppCard";
import { ProjectAddForm } from "./components/ProjectAddForm";
import { ProjectEditForm } from "./components/ProjectEditForm";

export const DAppsPannel = () => {
  const {
    dApps,
    setDApps,
    addDApps,
    page,
    setPage,
    hasMore,
    setHasMore,
    dAppForEdit,
    setDAppForEdit,
  } = useDAppsStore();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  useEffect(() => {
    const loadInitialData = async () => {
      const data = await dAppsAPI.getDApps(0);
      if (data.length === 0) {
        setHasMore(false);
      }
      setDApps(data);
      setPage(1);
    };
    loadInitialData();
  }, [setDApps, setPage]);

  const loadMore = async () => {
    const data = await dAppsAPI.getDApps(dApps.length);
    if (data.length === 0) {
      setHasMore(false);
    }
    setPage(page + 1);
    addDApps(data);
  };

  useEffect(() => {
    if (isAddFormOpen || !!dAppForEdit) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isAddFormOpen, dAppForEdit]);

  return (
    <div className="px-[14px] sm:px-[154px] pt-[112px] sm:pt-[184px] flex flex-col items-center">
      <Logo className="top-[40px] left-[40px] absolute" />
      <h1 className="mb-[50px] text-[24px] text-center px-[50px] sm:px-0 md:text-[36px] font-[700]">
        RetroBridge Dapps Admin Pannel
      </h1>
      <Button
        extraClass="flex items-center justify-center mb-[60px] min-w-[192px]"
        onClick={() => setIsAddFormOpen(true)}
      >
        <span className="mr-[10px] text-[18px]">+</span> Add new project
      </Button>

      <div className="w-full">
        <p className="font-[700] mb-[24px]">All projects ({dApps.length})</p>

        <InfiniteScroll
          dataLength={dApps.length}
          next={loadMore}
          hasMore={hasMore}
          loader={<p></p>}
          endMessage={<p></p>}
        >
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7 w-full mb-16">
            {dApps.map((dApp, i) => (
              <motion.article
                initial={{
                  y: 50,
                  opacity: 0,
                }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 0.6,
                  delay: 0.2 * (i % 3),
                }}
                viewport={{
                  once: true,
                  margin: "-20% 0%",
                }}
                key={dApp.id}
                className="relative cursor-pointer flex flex-col gap-[8px] min-h-[251px] py-[22px] pl-[19px] pr-[24px]"
                onClick={() => window.open(dApp.website, "_blank")}
              >
                <DAppCard dApp={dApp} />
              </motion.article>
            ))}
          </section>
        </InfiniteScroll>
      </div>

      <ProjectAddForm
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
      />

      {!!dAppForEdit && (
        <ProjectEditForm
          dApp={dAppForEdit}
          isOpen={!!dAppForEdit}
          onClose={() => setDAppForEdit(null)}
        />
      )}
    </div>
  );
};
