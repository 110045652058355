import axios, { AxiosInstance } from 'axios';
import { BOT_API_URL } from 'configs/api';
import { IBotNetwork, NetworkTypes } from 'types/networks';
import { warnInGroup } from 'utils/log';

class BotApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BOT_API_URL,
      withCredentials: true,
    });
  }

  async getNetworks(): Promise<IBotNetwork[]> {
    try {
      const { data } = await this._api.get(
        '/api/admin/rebalance-bot-networks',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data;
    } catch (error) {
      warnInGroup('NetworksApi.getNetworks', error);
    }
    return [];
  }

  async setBotSettings(tokens: IBotNetwork['tokens']) {
    const { data } = await this._api.post(
      '/api/admin/rebalance-bot-networks',
      {
        tokens,
      },
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );

    return data;
  }

  async downloadLogs() {
    const { data } = await this._api.get(
      '/api/admin/rebalance-bot-networks/download',
      {
        headers: { 'network-type': NetworkTypes.EVM },
        responseType: 'blob',
      }
    );

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.download = `rebalance-bot-logs-${Date.now()}.txt`;
    link.href = url;
    link.click();
    link.remove();
  }
}

export const botAPI = new BotApi();
