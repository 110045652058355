import { DAppsPannel } from "./components/Pannel";
import { DAppsSignIn } from "./components/SignIn";
import { useDAppsStore } from "./store";

export const DApps = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { isAuthorized } = useDAppsStore();

  return accessToken || isAuthorized ? <DAppsPannel /> : <DAppsSignIn />;
};
