import { useEffect, useState } from "react";

export const Slider = ({
  min = 1,
  max,
  value,
  setValue,
}: {
  min?: number;
  max: number;
  value: number;
  setValue: (value: number) => void;
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
  };

  const handleLabelClick = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(value);
  }, [value, setValue]);

  return (
    <div className="w-full flex flex-col items-center">
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleInputChange}
        className="w-full h-[4px] bg-[#1A1A1A] rounded-lg appearance-none cursor-pointer relative z-10"
        style={{
          background: `linear-gradient(to right, white ${
            ((value - min) / (max - min)) * 100
          }%, #1A1A1A 0)`,
        }}
      />
      <div className="w-[102%] flex justify-between mb-2 relative mt-[12px]">
        {[...Array(max - min + 1)].map((_, index) => (
          <div
            key={index}
            className="relative flex flex-col items-center cursor-pointer"
            onClick={() => handleLabelClick(min + index)}
          >
            <div
              className={`w-1 h-[12px] rounded-md z-10 ${
                value >= min + index ? "bg-white" : "bg-[#1A1A1A]"
              }`}
              style={{ marginTop: "-20px" }}
            />
            <span
              className={`font-[700] text-[14px] mt-2 cursor-pointer`}
            >
              {min + index}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
