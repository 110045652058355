import { Loader } from 'components/Loader';
import { useNotify } from 'hooks/useToast';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'services/auth';
import { useUserStore } from 'stores/user';
import { UserRoles } from 'types/user';
import { useAccount } from 'wagmi';

interface PrivateRouteProps {
  children: JSX.Element;
  role?: UserRoles;
}

export const PrivateRoute = ({
  children,
  role = UserRoles.ADMIN,
}: PrivateRouteProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { address } = useAccount();
  const user = useUserStore((s) => s.user);
  const { notify } = useNotify();

  const oldEthAddressRef = useRef<`0x${string}` | null>(null);

  const checkIsAuthWallet = async (address: string) => {
    try {
      await authAPI.checkWallet(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    if (!address) {
      navigate('/apanel/sign-in');
      return;
    }

    if (address === oldEthAddressRef.current) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    checkIsAuthWallet(address)
      .then((isAuth) => {
        setIsLoading(false);

        if (!user?.wallet_role && isAuth) return;

        if (pathname === '/apanel/sign-in' && isAuth) {
          if (role === UserRoles.ADMIN) {
            navigate('/apanel');
          } else if (role === UserRoles.MODERATOR) {
            navigate('/endpoints');
          }
        }
        if (!isAuth) {
          navigate('/apanel/sign-in');
        }

        if (role === UserRoles.ADMIN && user?.wallet_role !== UserRoles.ADMIN) {
          notify({
            meassage: 'You do not have enough permissions',
            type: 'error',
          });
          navigate('/apanel/sign-in');
          return;
        }

        if (
          role === UserRoles.MODERATOR &&
          user?.wallet_role !== UserRoles.MODERATOR &&
          user?.wallet_role !== UserRoles.ADMIN
        ) {
          notify({
            meassage: 'You do not have enough permissions',
            type: 'error',
          });
          navigate('/apanel/sign-in');
          return;
        }
      })
      .catch(() => {
        setIsLoading(false);
        navigate('/apanel/sign-in');
      });
  }, [pathname, address, role, user?.wallet_role]);

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return children;
};
