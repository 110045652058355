import { SVGAttributes } from 'react';

export function CopyIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      {...props}
    >
      <path
        d="M15.6667 6.75012H8.91675C8.08832 6.75012 7.41675 7.42169 7.41675 8.25012V15.0001C7.41675 15.8285 8.08832 16.5001 8.91675 16.5001H15.6667C16.4952 16.5001 17.1667 15.8285 17.1667 15.0001V8.25012C17.1667 7.42169 16.4952 6.75012 15.6667 6.75012Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.41675 11.2501H3.66675C3.26892 11.2501 2.88739 11.0921 2.60609 10.8108C2.32478 10.5295 2.16675 10.1479 2.16675 9.75012V3.00012C2.16675 2.6023 2.32478 2.22077 2.60609 1.93946C2.88739 1.65816 3.26892 1.50012 3.66675 1.50012H10.4167C10.8146 1.50012 11.1961 1.65816 11.4774 1.93946C11.7587 2.22077 11.9167 2.6023 11.9167 3.00012V3.75012"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
