import twitter from "../../../../assets/icons/twitter.png";
import { Discord } from "../../../../assets/icons/Discord";
import { RightArrow } from "../../../../assets/icons/RightArrow";
import TrashIcon from "assets/icons/TrashIcon";
import { IDApp } from "screens/DApps/types";
import { DeleteModal } from "../DeleteModal";
import { useEffect, useState } from "react";
import { dAppsAPI } from "screens/DApps/service";
import { ProjectEditForm } from "../Pannel/components/ProjectEditForm";
import { EditIcon } from "assets/icons/Edit";
import { useDAppsStore } from "screens/DApps/store";

export const DAppCard = ({ dApp }: { dApp: IDApp }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { setDAppForEdit } = useDAppsStore();

  const onDelete = () => {
    dAppsAPI.deleteDApp({ dAppId: dApp.id });
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <span className="dapp-page--grid-item-bg">
        <span className="dapp-page--grid-item-bg_1" />
      </span>
      <img
        src={`${dApp.image}`}
        className="min-w-[40px] w-[40px] h-[40px] object-cover rounded-full"
        alt=""
      />
      <h2 className="text-[18px] font-[500] leading-[24px] text-left">
        {dApp?.name}
      </h2>
      <span className="text-[14px] font-[500] leading-[18px] text-[#A39DC7]">
        {dApp.description}
      </span>
      <div className="flex mt-auto items-center">
        <div className="truncate max-w-full flex justify-start items-center py-[6px] px-[16px] bg-[#2B264B] rounded-full text-[14px] font-[500] leading-[18px] text-[#B7A5FF]">
          {dApp.tag}
        </div>
        <div className="ms-auto flex items-center gap-[16px]">
          {!!dApp.twitter_url && (
            <a
              href={dApp.twitter_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="X (Twitter)"
              className="flex justify-center items-center w-[16px] h-[16px]"
              style={{ textDecoration: "none" }}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={twitter} alt="" />
            </a>
          )}

          {!!dApp.discord_url && (
            <a
              href={dApp.discord_url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Discord"
              className="flex justify-center items-center"
              style={{ textDecoration: "none" }}
              onClick={(e) => e.stopPropagation()}
            >
              <Discord width={20} height={20} />
            </a>
          )}
        </div>
      </div>
      <div className="absolute top-0 right-0 flex gap-[8px]">
        <button
          className="dapp-page--grid-item--action"
          aria-label="Open"
          onClick={(e) => {
            e.stopPropagation();
            setDAppForEdit(dApp);
          }}
        >
          <EditIcon width={14} height={14} />
        </button>

        <button
          className="dapp-page--grid-item--action-delete"
          aria-label="Open"
          onClick={(e) => {
            e.stopPropagation();
            setIsDeleteModalOpen(true);
          }}
        >
          <TrashIcon width={16} height={16} />
        </button>
      </div>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
      />
    </>
  );
};
