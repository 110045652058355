import useSWR from 'swr';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useWindowResize } from 'hooks/useWindowResize';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { AxiosError } from 'axios';
import { chunk } from 'lodash';
import { hedgeApi } from 'services/hedge';
import { IHedgeToken } from 'types/hedgeTokens';
import { TokenItem } from './TokenItem';

export interface IHedgeTokensForm {
  tokens: IHedgeToken[];
}

export function HedgeTokens() {
  const { width } = useWindowResize();

  const { data, mutate } = useSWR(
    'hedgeTokens',
    () => hedgeApi.getHedgeTokens(),
    {
      revalidateOnFocus: false,
    }
  );
  const { notify } = useNotify();

  const form = useForm<IHedgeTokensForm>({
    values: {
      tokens: data || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const dirtyFields = form.formState.dirtyFields;
      const formValues = form.getValues();
      // Update tokens
      const updatedTokens: IHedgeToken[] = [];

      for (const key in dirtyFields.tokens) {
        const token = formValues.tokens[
          key as keyof typeof formValues.tokens
        ] as IHedgeToken;

        updatedTokens.push(token);
      }

      if (updatedTokens.length > 0) {
        await hedgeApi.updateHedgeTokens(updatedTokens);
        await mutate();
        notify({
          meassage: 'All changes saved!',
          type: 'success',
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  const [virtualGrid, cols] = useMemo(() => {
    let cols = 4;

    if (width < 768) {
      cols = 1;
    } else if (width < 1024) {
      cols = 2;
    } else if (width < 1280) {
      cols = 3;
    }

    return [
      data ? chunk(data, Math.ceil(data.length / cols)) : [],
      data ? Math.ceil(data.length / cols) : 0,
    ];
  }, [data, width]);

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="mb-[3.75rem] border-[#B5B2C9] border-opacity-30 md:mb-[3.3125rem] md:pb-[3.3125rem] md:border-b"
    >
      <div className="flex justify-between items-center pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Tokens to Hedge
        </h2>
        <Button
          type="submit"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          extraClass="hidden md:block"
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <FormProvider {...form}>
        <div className="grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-6">
          {data &&
            virtualGrid.map((chunk, k) => (
              <div key={`chunk-${k}`} className="flex flex-col gap-x-8 gap-y-6">
                {chunk.map((token, i) => (
                  <TokenItem
                    token={token}
                    i={i + k * cols}
                    key={i + k * cols}
                  />
                ))}
              </div>
            ))}
        </div>
      </FormProvider>
      <Button
        disabled={!form.formState.isDirty || form.formState.isSubmitting}
        type="submit"
        extraClass="block mx-auto mt-6 md:hidden"
      >
        {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  );
}
