import { CopyIcon } from 'assets/icons/Copy';
import { useNotify } from 'hooks/useToast';
import { weeklyQuestApi } from 'services/weeklyQuest';
import useSWR from 'swr';
import { useCopyToClipboard } from 'usehooks-ts';

export function Endpoints() {
  const { data } = useSWR(
    '/api/endpoints',
    () => weeklyQuestApi.getEndpoints(),
    {
      revalidateOnFocus: false,
    }
  );
  const copyToClipboard = useCopyToClipboard()[1];

  const { notify } = useNotify();

  return (
    <div className="pb-[3.3125rem] mb-[3.3125rem] border-opacity-30">
      <div className="flex justify-between items-center pb-8 md:pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Bridge & Swap Endpoints
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {data?.map((endpoint, i) => (
          <div key={i} className="flex flex-col gap-4">
            <h3 className="font-medium text-lg leading-5">
              {endpoint.preference_name}
            </h3>
            <div className="flex gap-2 items-center py-[0.9375rem] px-5 rounded-lg bg-white bg-opacity-10 font-medium text-sm">
              <input
                defaultValue={endpoint.link}
                className="truncate bg-transparent flex-grow outline-none"
                readOnly
              />
              <button
                type="button"
                className="ml-auto"
                onClick={() => {
                  copyToClipboard(endpoint.link);
                  notify({
                    meassage: 'Copied to clipboard!',
                    type: 'success',
                  });
                }}
              >
                <CopyIcon />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
