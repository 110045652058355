import * as React from "react";

const ImageIcon = ({ ...props }) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91667 2.25C2.54848 2.25 2.25 2.54848 2.25 2.91667V11.0833C2.25 11.3885 2.45507 11.6458 2.73494 11.7249L8.98011 5.47976C9.17537 5.2845 9.49195 5.2845 9.68721 5.47976L11.75 7.54255V2.91667C11.75 2.54848 11.4515 2.25 11.0833 2.25H2.91667ZM12.75 8.73189V2.91667C12.75 1.99619 12.0038 1.25 11.0833 1.25H2.91667C1.99619 1.25 1.25 1.99619 1.25 2.91667V11.0833C1.25 12.0038 1.99619 12.75 2.91667 12.75H11.0833C12.0038 12.75 12.75 12.0038 12.75 11.0833V8.76807C12.7504 8.75601 12.7504 8.74394 12.75 8.73189ZM11.75 8.95676L9.33366 6.54042L4.12408 11.75H11.0833C11.4515 11.75 11.75 11.4515 11.75 11.0833V8.95676ZM4.95801 4.58331C4.7509 4.58331 4.58301 4.75121 4.58301 4.95831C4.58301 5.16542 4.7509 5.33331 4.95801 5.33331C5.16511 5.33331 5.33301 5.16542 5.33301 4.95831C5.33301 4.75121 5.16511 4.58331 4.95801 4.58331ZM3.58301 4.95831C3.58301 4.19892 4.19862 3.58331 4.95801 3.58331C5.7174 3.58331 6.33301 4.19892 6.33301 4.95831C6.33301 5.7177 5.7174 6.33331 4.95801 6.33331C4.19862 6.33331 3.58301 5.7177 3.58301 4.95831Z"
      fill="url(#paint0_linear_10979_14574)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10979_14574"
        x1={7.00016}
        y1={1.25}
        x2={7.00016}
        y2={12.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DDB2FF" />
        <stop offset={1} stopColor="#BF97FF" />
      </linearGradient>
    </defs>
  </svg>
);
export default ImageIcon;
