import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { useState } from 'react';
import { botAPI } from 'services/bot';
import { warnInGroup } from 'utils/log';

export function DownloadLogs() {
  const [isLogsDownloading, setIsLogsDownloading] = useState(false);
  const { notify } = useNotify();

  const handleDownloadLogs = async () => {
    try {
      setIsLogsDownloading(true);
      await botAPI.downloadLogs();
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
      warnInGroup('DownloadLogs.handleDownloadLogs', error);
    } finally {
      setIsLogsDownloading(false);
    }
  };

  return (
    <Button
      onClick={handleDownloadLogs}
      disabled={isLogsDownloading}
      extraClass="lg:absolute right-0 mx-auto mt-4 lg:mt-0 lg:ml-auto"
    >
      {isLogsDownloading ? 'Downloading...' : 'Download Logs'}
    </Button>
  );
}
