import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import { NetworkTypes } from 'types/networks';
import { ISwapPair } from 'types/pairs';
import { warnInGroup } from 'utils/log';

export class PairsApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getSwapPairs(): Promise<ISwapPair[]> {
    try {
      const { data } = await this._api.get('/api/admin/swap-pairs-grouped', {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data.data;
    } catch (error) {
      warnInGroup('PairsApi.getSwapPairs', error);
    }
    return [];
  }

  async updateSwapPairs(swapPairs: ISwapPair[]): Promise<void> {
    try {
      const { data } = await this._api.put(
        '/api/admin/swap-pairs-grouped',
        {
          swapPairs,
        },
        { headers: { 'network-type': NetworkTypes.EVM } }
      );

      return data.data;
    } catch (error) {
      warnInGroup('PairsApi.updateSwapPairs', error);
    }
  }
}

export const pairsApi = new PairsApi();
