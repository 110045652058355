import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  Root,
} from '@radix-ui/react-dialog';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export function Modal({ isOpen, children, onOpenChange }: ModalProps) {
  return (
    <Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <DialogContent className="bg-[#030016] rounded-[20px]">
            {children}
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Root>
  );
}
