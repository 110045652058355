import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { FormProvider, useForm } from 'react-hook-form';
import { weeklyQuestApi } from 'services/weeklyQuest';
import useSWR from 'swr';
import { WeeklyItem } from './Item';
import { WeeklyQuestDateForm } from './scheme';

export function WeeklyQuestDate() {
  const { data, mutate } = useSWR(
    'weeklyQuestDates/galxe',
    () => weeklyQuestApi.getDates(),
    {
      revalidateOnFocus: false,
    }
  );
  const { notify } = useNotify();

  const form = useForm<WeeklyQuestDateForm>({
    values: {
      dates: data?.map((el) => ({
        ...el,
        start_date: el.start_date ? new Date(el.start_date) : undefined,
        end_date: el.end_date ? new Date(el.end_date) : undefined,
      })) ?? [],
    },
  });

  const handleSubmit = async () => {
    try {
      const values = form.getValues().dates;

      for (const dates of values) {
        if (
          (!dates.start_date && dates.end_date) ||
          (dates.start_date && !dates.end_date)
        ) {
          throw new Error('Please fill in all fields');
        }
        if (
          dates.start_date &&
          dates.end_date &&
          dates.start_date.getTime() > dates.end_date.getTime()
        ) {
          throw new Error('Start date must be before end date');
        }
      }

      for (const dates of values) {
        dates.start_date?.setUTCHours(0);
        dates.start_date?.setUTCMinutes(0);
        dates.start_date?.setUTCSeconds(0);
        dates.start_date?.setUTCMilliseconds(0);

        dates.end_date?.setUTCHours(23);
        dates.end_date?.setUTCMinutes(59);
        dates.end_date?.setUTCSeconds(59);
        dates.end_date?.setUTCMilliseconds(999);

        await weeklyQuestApi.setDates({
          preference_name: dates.setting_name,
          setting_name: dates.setting_name,
          start_date: dates.start_date?.toISOString(),
          end_date: dates.end_date?.toISOString(),
        });
      }

      await mutate();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        className="pb-[3.3125rem] mb-[3.3125rem] md:border-b border-[#B5B2C9] border-opacity-30"
      >
        <div className="flex justify-between items-center pb-8 md:pb-10">
          <h2 className="font-medium text-lg leading-5 md:text-2xl">
            Weekly quest date
          </h2>
          <Button
            type="submit"
            extraClass="hidden md:block"
            disabled={!form.formState.isDirty || form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
          </Button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {data?.map((el, i) => <WeeklyItem index={i} key={el.setting_name} />)}
        </div>

        <Button
          type="submit"
          extraClass="block md:hidden mx-auto mt-6"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </form>
    </FormProvider>
  );
}
