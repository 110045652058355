export enum NetworkTypes {
  EVM = 'EVM',
  ZK_SYNC_ERA = 'ZK_SYNC_ERA',
  STARKNET = 'STARKNET',
}

export interface INetwork {
  id: string;
  name: string;
  chainId: string;
  network_image_url: string;
  blockscan_url: string;
  token_symbol: string;
  token_decimals: number;
  network_type: NetworkTypes;
  category: string;
  active: boolean;
  native_transfer_gas: string;
  gas_price: string;
  gas_multiplier: number;
}

export interface IBotNetwork {
  network: string;
  network_image: string;
  tokens: {
    id: number;
    network: string;
    symbol: string;
    min_balance: number;
    target_balance: number;
    round_balance_to_decimals: number;
    type_of_env: string;
    disabled: boolean;
    token_image: string;
    created_at: string;
    updated_at: string;
  }[];
}

export interface INetworkWithCurrencies extends INetwork {
  currencies: ICurrency[];
}

export interface BalancerThresholdConfigs extends INetwork {
  currencies: (ICurrency & {
    min_balance: number;
    high_balance: number;
  })[];
}

export interface BalancerThresholdConfigsBody {
  [key: ICurrency['symbol']]: {
    [key: INetwork['name']]: number;
  };
}

export interface ICurrency {
  id: string;
  symbol: string;
  contract: {
    id: string;
    address: string;
    network: INetwork;
    type: string;
  };
  decimals: number;
  image_url: string;
  min_send: number;
  max_send: number;
  min_receive: number;
  max_receive: number;
  swap_fee: number;
  active: boolean;
  gas_limit_multiplier: number;
}
