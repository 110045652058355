import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { advancedModeApi } from 'services/advancedMode';
import useSWR from 'swr';

interface AdvancedModeForm {
  Sequence: string;
  Fast: string;
}

export function AdvancedMode() {
  const { data, mutate } = useSWR(
    'advancedModeDelay',
    () => advancedModeApi.getDelays(),
    {
      revalidateOnFocus: false,
    }
  );
  const form = useForm<AdvancedModeForm>({
    values: {
      Sequence: (data?.Sequence || 0) / 1000 + ' sec',
      Fast: (data?.Fast || 0) / 1000 + ' sec',
    },
  });

  const { notify } = useNotify();
  const Sequence = form.watch('Sequence');
  const Fast = form.watch('Fast');

  const isDirty = useMemo(() => {
    const defaultSequence = (data?.Sequence || 0) / 1000;
    const defaultFast = (data?.Fast || 0) / 1000;

    return (
      parseFloat(Sequence) !== defaultSequence ||
      parseFloat(Fast) !== defaultFast
    );
  }, [Sequence, Fast, data?.Sequence, data?.Fast]);

  const handleSubmit = async (formData: AdvancedModeForm) => {
    try {
      await advancedModeApi.setDelays({
        Sequence: parseFloat(formData.Sequence) * 1000,
        Fast: parseFloat(formData.Fast) * 1000,
      });

      await mutate();
      form.reset();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="pb-[3.3125rem] mb-[3.3125rem] border-[#B5B2C9] border-opacity-30 md:border-b"
    >
      <div className="flex justify-between items-center pb-8 md:pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Advanced Mode Delay
        </h2>
        <Button
          type="submit"
          extraClass="hidden md:block"
          disabled={!isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <div className="w-full flex flex-col gap-[0.8125rem]">
        <div className="flex gap-[0.8125rem]">
          <div className="grow flex flex-col gap-1">
            <label
              htmlFor="sequenceDelay"
              className="font-medium w-max text-xs leading-4 text-[#B5B2C9]"
            >
              Sequence Delay
            </label>
            <Controller
              name="Sequence"
              control={form.control}
              render={({ field }) => (
                <input
                  id="sequenceDelay"
                  className="grow w-full h-12 px-5 bg-white bg-opacity-10 rounded-lg text-sm leading-4 outline-none"
                  autoComplete="off"
                  value={field.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!/^[0-9]*(\.([0-9]+)?)?$/.test(val)) return;
                    field.onChange(val);
                  }}
                  onFocus={(e) => {
                    if (e.target.value.includes(' sec')) {
                      field.onChange(e.target.value.replace(' sec', ''));
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.endsWith(' sec')) {
                      field.onChange((e.target.value || 0) + ' sec');
                    }
                    field.onBlur();
                  }}
                />
              )}
            />
          </div>
          <div className="grow flex flex-col gap-1">
            <label
              htmlFor="fastDelay"
              className="font-medium w-max text-xs leading-4 text-[#B5B2C9]"
            >
              Fast Mode Delay
            </label>
            <Controller
              name="Fast"
              control={form.control}
              render={({ field }) => (
                <input
                  id="fastDelay"
                  className="grow w-full h-12 px-5 bg-white bg-opacity-10 rounded-lg text-sm leading-4 outline-none"
                  autoComplete="off"
                  value={field.value.toString()}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!/^[0-9]*(\.([0-9]+)?)?$/.test(val)) return;
                    field.onChange(val);
                  }}
                  onFocus={(e) => {
                    if (e.target.value.includes(' sec')) {
                      field.onChange(e.target.value.replace(' sec', ''));
                    }
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.endsWith(' sec')) {
                      field.onChange((e.target.value || 0) + ' sec');
                    }
                    field.onBlur();
                  }}
                />
              )}
            />
          </div>
        </div>
        <Button
          type="submit"
          extraClass="block md:hidden mx-auto mt-6"
          disabled={!isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
    </form>
  );
}
