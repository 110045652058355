import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { botAPI } from 'services/bot';
import useSWR from 'swr';
import { DownloadLogs } from './components/DownloadLogs';
import { LowBalanceAlert } from './components/LowBalanceAlert';
import { NetworkRabalance } from './components/NetworkRebalance';
import { TargetBalanceAlert } from './components/TargetBalance';

export function RebalanceBotView() {
  const { isLoading } = useSWR('bot-networks', () => botAPI.getNetworks(), {
    revalidateOnFocus: false,
  });

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          <Header />
          <main className="min-h-main main px-5 md:px-[6.0625rem]">
            <div className="relative flex flex-col lg:flex-row justify-center items-center mt-[1.125rem] mb-[3.125rem] md:mb-10 md:pt-[4.5rem] md:pb-[6.25rem]">
              <h1 className="w-[180px] font-bold text-center text-[1.75rem] md:w-auto md:text-[2.625rem]">
                Rebalance bot
              </h1>
              <DownloadLogs />
            </div>
            <NetworkRabalance />
            <LowBalanceAlert />
            <TargetBalanceAlert />
          </main>
        </>
      )}
    </>
  );
}
