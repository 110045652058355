import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import { IRpc, NetworkWithRpc } from 'types/networkRpc';
import { NetworkTypes } from 'types/networks';

class NetworkRpcApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getNetworks(isFrontendUsed: boolean = false): Promise<NetworkWithRpc[]> {
    try {
      const { data } = await this._api.get<NetworkWithRpc[]>(
        `/api/admin/get_networks_with_rpcs/${isFrontendUsed}`,
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data;
    } catch (error) {}
    return [];
  }

  async updateNetworks(rpcs: Partial<IRpc>[]): Promise<NetworkWithRpc[]> {
    try {
      await this._api.put<NetworkWithRpc[]>(
        '/api/admin/change_rpcs_data',
        {
          rpcs,
        },
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );
    } catch (error) {}
    return [];
  }
}

export const networkRpcApi = new NetworkRpcApi();
