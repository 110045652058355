import { useNotify } from 'hooks/useToast';
import { useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { authAPI } from 'services/auth';
import { AppTypedData } from 'types';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';

export function AuthController() {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { signMessageAsync, data } = useSignMessage();
  const oldEthAddressRef = useRef(address);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { notify } = useNotify();

  const checkIsAuthWallet = async (address: string) => {
    try {
      await authAPI.checkWallet(address);
      return true;
    } catch (error) {
      if (error instanceof Error && error.message === 'Not an admin wallet') {
        notify({
          meassage: 'Not an admin wallet',
          type: 'error',
        });
      }
      return false;
    }
  };

  const getTypedDataWithAddress = async (
    address: string
  ): Promise<AppTypedData> => {
    const { data } = await authAPI.getSignMessage();

    data.message.value = `${data.message.value}\n${address}`;
    return data;
  };

  const deactivateEthWallet = useCallback(() => {
    window.sessionStorage.removeItem('provider');
    disconnect();
  }, [disconnect]);

  const signEthWalletHelper = useCallback(
    async (address: string) => {
      try {
        const typedData = await getTypedDataWithAddress(address);
        await signMessageAsync({ message: typedData.message.value });
      } catch (error) {
        deactivateEthWallet();
      }
    },
    [deactivateEthWallet]
  );

  const signEthWallet = useCallback(
    async (address?: `0x${string}`) => {
      if (!address || address === oldEthAddressRef.current) {
        return;
      }

      const isAuth = await checkIsAuthWallet(address);

      if (isAuth) {
        if (pathname === '/apanel/sign-in') {
          navigate('/apanel');
        }
        return;
      }

      oldEthAddressRef.current = address;
      await signEthWalletHelper(address);
    },
    [signEthWalletHelper]
  );

  useEffect(() => {
    if (data && address) {
      authAPI
        .validateSignedMessage(address, data)
        .then(() => {
          if (pathname === '/apanel/sign-in') {
            navigate('/apanel');
          }
        })
        .catch((e) => {
          if (e instanceof Error && e.message === 'Not an admin wallet') {
            notify({
              meassage: 'Not an admin wallet',
              type: 'error',
            });
            deactivateEthWallet();
          }
        });
    }
  }, [data, address]);

  useEffect(() => {
    if (address) {
      signEthWallet(address);
    }
    oldEthAddressRef.current = address;
  }, [address, signEthWallet]);

  return <Outlet />;
}
