import useSWR from 'swr';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useWindowResize } from 'hooks/useWindowResize';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { AxiosError } from 'axios';
import { chunk } from 'lodash';
import { hedgeApi } from 'services/hedge';
import {
  IHedgeLowBalanceSetting,
  IHedgeLowBalanceSettings,
} from 'types/hedgeTokens';
import { LowBalanceSettingItem } from './item';

export interface IHedgeLowBalanceSettingsForm {
  settings: IHedgeLowBalanceSetting[];
}

export function LowBalanceSettings() {
  const { width } = useWindowResize();

  const { data, mutate } = useSWR(
    'hedgeLowBalanceSettings',
    () => hedgeApi.getLowBalanceSettings(),
    {
      revalidateOnFocus: false,
    }
  );
  const { notify } = useNotify();

  const form = useForm<IHedgeLowBalanceSettingsForm>({
    values: {
      settings: data || [],
    },
  });

  const handleSubmit = async () => {
    try {
      const settings = form.getValues().settings;
      const settingsObj: IHedgeLowBalanceSettings = {};
      settings.forEach((s) => {
        settingsObj[s.symbol] = s.minBalance;
      });

      await hedgeApi.updateLowBalanceSettings(settingsObj);
      await mutate();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  const [virtualGrid, cols] = useMemo(() => {
    let cols = 4;

    if (width < 768) {
      cols = 1;
    } else if (width < 1024) {
      cols = 2;
    } else if (width < 1280) {
      cols = 3;
    }

    return [
      data ? chunk(data, Math.ceil(data.length / cols)) : [],
      data ? Math.ceil(data.length / cols) : 0,
    ];
  }, [data, width]);

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="mb-[3.75rem] border-[#B5B2C9] border-opacity-30 md:mb-[3.3125rem] md:pb-[3.3125rem] md:border-b"
    >
      <div className="flex justify-between items-center pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Low balance alerts
        </h2>
        <Button
          type="submit"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
          extraClass="hidden md:block"
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <FormProvider {...form}>
        <div className="grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-6">
          {data &&
            virtualGrid.map((chunk, k) => (
              <div key={`chunk-${k}`} className="flex flex-col gap-x-8 gap-y-6">
                {chunk.map((item, i) => (
                  <LowBalanceSettingItem
                    item={item}
                    i={i + k * cols}
                    key={i + k * cols}
                  />
                ))}
              </div>
            ))}
        </div>
      </FormProvider>
      <Button
        disabled={!form.formState.isDirty || form.formState.isSubmitting}
        type="submit"
        extraClass="block mx-auto mt-6 md:hidden"
      >
        {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
      </Button>
    </form>
  );
}
