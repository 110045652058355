import clsx from 'clsx';

import { Controller, useFormContext } from 'react-hook-form';
import { IHedgeLowBalanceSetting } from 'types/hedgeTokens';
import { IHedgeLowBalanceSettingsForm } from '.';
import { useImgPreloader } from 'hooks/useImagePreload';
import { BASE_URL } from 'configs/api';

interface LowBalanceSettingItemProps {
  item: IHedgeLowBalanceSetting;
  i: number;
}

export function LowBalanceSettingItem({ item, i }: LowBalanceSettingItemProps) {
  const form = useFormContext<IHedgeLowBalanceSettingsForm>();

  const { imgsPreloaded } = useImgPreloader([BASE_URL + item.image_url]);

  return (
    <div className="inline-flex flex-col">
      <div className={clsx('flex items-center text-sm leading-[1.0625rem')}>
        <div className="relative w-7 min-w-7 h-7 mr-3">
          <img
            src={BASE_URL + item.image_url}
            className={clsx(
              'w-7 min-w-7 h-7 transition-all duration-200',
              imgsPreloaded ? 'opacity-100' : 'opacity-0'
            )}
            alt=""
          />
        </div>
        <div
          className={clsx(
            'mr-auto transition-colors duration-200 text-[#A39DC7]'
          )}
        >
          {item.symbol}
        </div>
        <div className="relative flex items-center ml-6 pr-6">
          <Controller
            name={`settings.${i}.minBalance`}
            control={form.control}
            rules={{ required: true }}
            render={({ field }) => (
              <input
                type="number"
                min={0.000001}
                step={0.000001}
                className="grow w-full flex lg:max-w-[145px] items-center h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text input-number"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
                required
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
