export const EditIcon = ({ ...props }) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_12120_31940)">
      <path
        d="M6.41663 2.33337H2.33329C2.02387 2.33337 1.72713 2.45629 1.50833 2.67508C1.28954 2.89388 1.16663 3.19062 1.16663 3.50004V11.6667C1.16663 11.9761 1.28954 12.2729 1.50833 12.4917C1.72713 12.7105 2.02387 12.8334 2.33329 12.8334H10.5C10.8094 12.8334 11.1061 12.7105 11.3249 12.4917C11.5437 12.2729 11.6666 11.9761 11.6666 11.6667V7.58337"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7916 1.45838C11.0237 1.22632 11.3384 1.09595 11.6666 1.09595C11.9948 1.09595 12.3096 1.22632 12.5416 1.45838C12.7737 1.69045 12.9041 2.0052 12.9041 2.33338C12.9041 2.66157 12.7737 2.97632 12.5416 3.20838L6.99996 8.75005L4.66663 9.33338L5.24996 7.00005L10.7916 1.45838Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12120_31940">
        <rect width={14} height={14} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
