import { SVGAttributes } from "react";

export function WalletIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M14.0625 12.5C14.7529 12.5 15.3125 11.9404 15.3125 11.25C15.3125 10.5596 14.7529 10 14.0625 10C13.3721 10 12.8125 10.5596 12.8125 11.25C12.8125 11.9404 13.3721 12.5 14.0625 12.5Z"
        fill="white"
      />
      <path
        d="M3.12502 5.3125V15C3.12502 15.3315 3.25672 15.6495 3.49114 15.8839C3.72556 16.1183 4.0435 16.25 4.37502 16.25H16.875C17.0408 16.25 17.1998 16.1842 17.317 16.0669C17.4342 15.9497 17.5 15.7908 17.5 15.625V7.5C17.5 7.33424 17.4342 7.17527 17.317 7.05806C17.1998 6.94085 17.0408 6.875 16.875 6.875H4.72497C3.87773 6.875 3.15614 6.21618 3.12604 5.36948C3.11839 5.15963 3.15311 4.95039 3.22814 4.75426C3.30316 4.55814 3.41695 4.37915 3.5627 4.22798C3.70845 4.07681 3.88318 3.95658 4.07644 3.87445C4.2697 3.79233 4.47754 3.75 4.68752 3.75H15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
