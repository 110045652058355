import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import {
  IHedgeLowBalanceSetting,
  IHedgeLowBalanceSettings,
  IHedgeToken,
} from 'types/hedgeTokens';
import { NetworkTypes } from 'types/networks';
import { warnInGroup } from 'utils/log';

export class HedgeApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getHedgeTokens(): Promise<IHedgeToken[]> {
    try {
      const { data } = await this._api.get('/api/admin/hedge-tokens', {
        headers: { 'network-type': NetworkTypes.EVM },
      });

      return data;
    } catch (error) {
      warnInGroup('HedgeApi.getHedgeTokens', error);
    }
    return [];
  }

  async updateHedgeTokens(hedgeTokens: IHedgeToken[]): Promise<void> {
    try {
      const { data } = await this._api.put(
        '/api/admin/hedge-tokens',
        {
          hedgeTokens,
        },
        { headers: { 'network-type': NetworkTypes.EVM } }
      );

      return data;
    } catch (error) {
      warnInGroup('HedgeApi.updateHedgeTokens', error);
    }
  }

  async getLowBalanceSettings(): Promise<IHedgeLowBalanceSetting[]> {
    try {
      const { data } = await this._api.get(
        '/api/admin/hedge-low-balance-settings',
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data;
    } catch (error) {
      warnInGroup('HedgeApi.getLowBalanceSettings', error);
    }
    return [];
  }

  async updateLowBalanceSettings(
    settings: IHedgeLowBalanceSettings
  ): Promise<void> {
    try {
      const { data } = await this._api.put(
        '/api/admin/hedge-low-balance-settings',
        { settings },
        {
          headers: { 'network-type': NetworkTypes.EVM },
        }
      );

      return data;
    } catch (error) {
      warnInGroup('HedgeApi.updateLowBalanceSettings', error);
    }
  }
}

export const hedgeApi = new HedgeApi();
