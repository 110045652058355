import { Plus } from 'assets/icons/Plus';
import { Trashcan } from 'assets/icons/Trashcan';
import { WalletIcon } from 'assets/icons/Wallet';
import { AxiosError } from 'axios';
import { Button } from 'components/Button';
import { useNotify } from 'hooks/useToast';
import { useWindowResize } from 'hooks/useWindowResize';
import { differenceWith } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { walletsAPI } from 'services/wallets';
import useSWR from 'swr';
import { WalletInList } from 'types/wallets';

interface FreezeListForm {
  wallets: Pick<WalletInList, 'walletAddress'>[];
}

export function FreezeList() {
  const { width } = useWindowResize();
  const { data, mutate } = useSWR(
    'freezeList',
    () => walletsAPI.getFreezeList(),
    {
      revalidateOnFocus: false,
    }
  );
  const form = useForm<FreezeListForm>({
    values: {
      wallets: data ?? [],
    },
  });
  const { fields, append, remove } = useFieldArray<FreezeListForm>({
    control: form.control,
    name: 'wallets',
  });

  const { notify } = useNotify();

  const handleSubmit = async (formData: FreezeListForm) => {
    if (!data) return;

    try {
      const newWallets = differenceWith(
        formData.wallets,
        data,
        (a, b) => a.walletAddress === b.walletAddress
      ).filter((wallet) => wallet.walletAddress.trim() !== '');

      const deletedWallets = differenceWith(
        data,
        formData.wallets,
        (a, b) => a.walletAddress === b.walletAddress
      );

      if (deletedWallets.length >= 0) {
        await walletsAPI.removeFromFreezeList(
          deletedWallets.map((wallet) => wallet.walletAddress)
        );
      }

      if (newWallets.length >= 0) {
        await walletsAPI.addToFreezeList(
          newWallets.map((wallet) => wallet.walletAddress)
        );
      }

      await mutate();
      form.reset();
      notify({
        meassage: 'All changes saved!',
        type: 'success',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        notify({
          meassage: error.response?.data.message || error.message,
          type: 'error',
        });
      } else if (error instanceof Error) {
        notify({
          meassage: error.message,
          type: 'error',
        });
      }
    }
  };

  return (
    <form
      onSubmit={form.handleSubmit(handleSubmit)}
      className="pb-[3.3125rem] mb-[3.3125rem] md:border-b border-[#B5B2C9] border-opacity-30"
    >
      <div className="flex justify-between items-center pb-8 md:pb-10">
        <h2 className="font-medium text-lg leading-5 md:text-2xl">
          Freeze List
        </h2>
        <Button
          type="submit"
          extraClass="hidden md:block"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
      <div className="w-full md:w-[25rem] flex flex-col gap-[0.8125rem]">
        {fields.map((field, i) => (
          <div className="flex flex-col gap-2" key={field.id}>
            <span className="font-medium text-sm leading-[1.0625rem] cursor-text">
              Address
            </span>
            <div className="flex items-center gap-2 md:gap-[0.8125rem]">
              <label className="flex items-center gap-2 w-full md:max-w-[363px] h-12 px-5 py-[0.9375rem] rounded-lg bg-white bg-opacity-10 font-medium text-sm leading-[1.0625rem] text-white outline-none cursor-text">
                <span className="flex justify-center items-center w-5 min-w-5 h-5 bg-white rounded-full text-black">
                  <WalletIcon width={14} height={14} />
                </span>
                <input
                  type="text"
                  className="grow bg-transparent outline-none font-medium text-sm leading-[1.0625rem] text-white"
                  autoComplete="off"
                  {...form.register(`wallets.${i}.walletAddress`)}
                />
              </label>
              <button
                type="button"
                aria-label="Delete field"
                onClick={() => remove(i)}
              >
                <Trashcan
                  width={width >= 768 ? 24 : 18}
                  height={width >= 768 ? 24 : 18}
                />
              </button>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="flex justify-center items-center gap-2 text-sm font-bold text-gradient mr-auto mt-4"
          onClick={() => append({ walletAddress: '' })}
        >
          <Plus />
          Add address
        </button>
        <Button
          type="submit"
          extraClass="block md:hidden mx-auto mt-6"
          disabled={!form.formState.isDirty || form.formState.isSubmitting}
        >
          {form.formState.isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </div>
    </form>
  );
}
