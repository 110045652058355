import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { networksApi } from 'services/networks';
import useSWR from 'swr';
import { AdvancedMode } from './components/AdvancedMode';
import { BlockList } from './components/BlockList';
import { FreezeList } from './components/FreezeList';
import { GasLimitsMultipliers } from './components/GasLimitsMultipliers';
import { NetworkDestinationLimits } from './components/NetworkDestinationLimits';
import { NetworkMultiplier } from './components/NetworkMultiplier';
import { NetworkRpc } from './components/NetworkRpc';
import { NetworkSourceLimits } from './components/NetworkSourceLimits';
import { NetworkStatus } from './components/NetworksStatus';
import { LowBalanceAlert } from './components/LowBalanceAlert';
import { HighBalanceAlert } from './components/HighBalanceAlert';
import { NetworkFERpc } from './components/NetworkFERpc';

export function MainView() {
  const { isLoading } = useSWR('networks', () => networksApi.getNetworks(), {
    revalidateOnFocus: false,
  });

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          <Header />
          <main className="min-h-main main px-5 md:px-[6.0625rem]">
            <div className="flex justify-center items-center mt-[1.125rem] mb-[3.125rem] md:mb-10 md:pt-[4.5rem] md:pb-[6.25rem]">
              <h1 className="w-[180px] font-bold text-center text-[1.75rem] md:w-auto md:text-[2.625rem]">
                RetroBridge Admin Pannel
              </h1>
            </div>
            <NetworkStatus />
            <NetworkSourceLimits />
            <NetworkDestinationLimits />
            <NetworkMultiplier />
            <GasLimitsMultipliers />
            <NetworkFERpc />
            <NetworkRpc />
            <BlockList />
            <FreezeList />
            <AdvancedMode />
            <LowBalanceAlert />
            <HighBalanceAlert />
          </main>
        </>
      )}
    </>
  );
}
