import { SwitchProps, Thumb, Root } from "@radix-ui/react-switch";
import { forwardRef } from "react";

export const ProjectFormSwitch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ checked, onCheckedChange, ...props }, ref) => {
    return (
      <Root
        {...props}
        className={`h-[28px] min-w-[50px] w-[50px] pr-1 pl-[0.3rem] flex justify-start items-center rounded-full ${
          checked ? "bg-switch" : "bg-[#FFFFFF1C]"
        }`}
        checked={checked}
        onCheckedChange={onCheckedChange}
      >
        <Thumb className="block w-[20px] h-[20px] bg-white bg-opacity-20 rounded-full transition-all duration-200 data-[state=checked]:bg-opacity-100 data-[state=checked]:translate-x-[1.3rem]" />
      </Root>
    );
  }
);
