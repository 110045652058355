import { Header } from 'components/Header';
import { Loader } from 'components/Loader';

import { SwapPairsStatus } from './components/SwapPairs';
import { HedgeTokens } from './components/HedgeTokens';
import { SwapFees } from './components/SwapFees';
import { LowBalanceSettings } from './components/LowBalanceAlerts';

export function HedgeBotView() {
  const isLoading = false;

  return (
    <>
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <>
          <Header />
          <main className="min-h-main main px-5 md:px-[6.0625rem]">
            <div className="flex justify-center items-center mt-[1.125rem] mb-[3.125rem] md:mb-10 md:pt-[4.5rem] md:pb-[6.25rem]">
              <h1 className="w-[180px] font-bold text-center text-[1.75rem] md:w-auto md:text-[2.625rem]">
                RetroBridge Admin Pannel
              </h1>
            </div>
            <HedgeTokens />
            <SwapPairsStatus />
            <SwapFees />
            <LowBalanceSettings />
          </main>
        </>
      )}
    </>
  );
}
