import { Header } from 'components/Header';
import { Endpoints } from './components/Endpoints';
import { WeeklyQuestDate } from './components/WeeklyQuestDate';

export function ApiEndPointsView() {
  return (
    <>
      <Header />
      <main className="min-h-main main px-5 md:px-[6.0625rem]">
        <div className="flex justify-center items-center mt-[1.125rem] mb-[3.125rem] md:mb-10 md:pt-[4.5rem] md:pb-[6.25rem]">
          <h1 className="w-[180px] font-bold text-center text-[1.75rem] md:w-auto md:text-[2.625rem]">
            API EndPoints
          </h1>
        </div>
        <WeeklyQuestDate />
        <Endpoints />
      </main>
    </>
  );
}
