import * as React from "react";

const ExpandArrow = ({ ...props }) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 12L5.10289 7.5L12.8971 7.5L9 12Z" fill="white" />
  </svg>
);
export default ExpandArrow;
