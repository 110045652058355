import axios, { AxiosInstance } from 'axios';
import { BASE_URL } from 'configs/api';
import { NetworkTypes } from 'types/networks';
import { WalletInList } from 'types/wallets';

class WalletsApi {
  private _api: AxiosInstance;

  constructor() {
    this._api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    });
  }

  async getBlacklist() {
    const url = `/api/admin/get_all_wallets_in_black_list`;

    const { data } = await this._api.get<WalletInList[]>(url, {
      headers: { 'network-type': NetworkTypes.EVM },
    });

    return data.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }

  async addToBlacklist(wallets: string[]) {
    if (wallets.length === 0) return;
    const url = `/api/admin/add_wallets_to_black_list`;

    const { data } = await this._api.post(
      url,
      { wallets },
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );

    return data;
  }

  async removeFromBlacklist(wallets: string[]) {
    if (wallets.length === 0) return;
    const url = `/api/admin/delete_wallets_from_black_list?wallets=${wallets.join(',')}`;

    const { data } = await this._api.delete(url, {
      headers: { 'network-type': NetworkTypes.EVM },
    });

    return data;
  }

  async getFreezeList() {
    const url = `/api/admin/get_all_wallets_in_freeze_list`;

    const { data } = await this._api.get<WalletInList[]>(url, {
      headers: { 'network-type': NetworkTypes.EVM },
    });

    return data.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }

  async addToFreezeList(wallets: string[]) {
    if (wallets.length === 0) return;
    const url = `/api/admin/add_wallets_to_freeze_list`;

    const { data } = await this._api.post(
      url,
      { wallets },
      {
        headers: { 'network-type': NetworkTypes.EVM },
      }
    );

    return data;
  }

  async removeFromFreezeList(wallets: string[]) {
    if (wallets.length === 0) return;
    const url = `/api/admin/delete_wallets_from_freeze_list?wallets=${wallets.join(
      ','
    )}`;

    const { data } = await this._api.delete(url, {
      headers: { 'network-type': NetworkTypes.EVM },
    });

    return data;
  }
}

export const walletsAPI = new WalletsApi();
